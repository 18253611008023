// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-data-pages-404-index-tsx": () => import("./../../../src/data/pages/404/index.tsx" /* webpackChunkName: "component---src-data-pages-404-index-tsx" */),
  "component---src-data-pages-forkthesystem-scruminraci-index-tsx": () => import("./../../../src/data/pages/forkthesystem/scruminraci/index.tsx" /* webpackChunkName: "component---src-data-pages-forkthesystem-scruminraci-index-tsx" */),
  "component---src-data-pages-index-tsx": () => import("./../../../src/data/pages/index.tsx" /* webpackChunkName: "component---src-data-pages-index-tsx" */),
  "component---src-data-pages-search-index-tsx": () => import("./../../../src/data/pages/search/index.tsx" /* webpackChunkName: "component---src-data-pages-search-index-tsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/_default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

